import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { ToastrService } from "ngx-toastr";
import { SwiperOptions } from "swiper";
import { fileFavoriteConfig } from "@app/pages/associations/shared/const/association-swiper.const";
import { SwiperComponent } from "swiper/angular";
import { GlobalFormDialog } from "@app/shared/dialogs/global-form/container/global-form-dialog";
import { dialogConfig } from "@app/core/const/dialog.const";
import { GlobalFormDialogDataModel } from "@app/shared/dialogs/global-form/model";
import { MatDialog } from "@angular/material/dialog";
import { ChangeOwnershipDialogComponent } from "@app/pages/associations/dialog/change-ownership-dialog/change-ownership-dialog.component";

@Component({
  selector: "app-association-summary",
  templateUrl: "./association-summary.component.html",
  styleUrls: ["./association-summary.component.scss"],
})
export class AssociationSummaryComponent implements OnInit {
  @Input() associationInfo: any;

  public accounts: any[] = [];
  public details: any = {};
  public policies: any = {};
  public services: any = {};

  public statistics: any = {};
  public logs: any[] = [];
  public bankAccounts = new MatTableDataSource<any[]>([]);
  public contacts = new MatTableDataSource<any[]>([]);
  public requests = new MatTableDataSource<any[]>([]);
  public meetings = new MatTableDataSource<any[]>([]);
  public violations = new MatTableDataSource<any[]>([]);
  public swiperConfig: SwiperOptions = fileFavoriteConfig;

  public displayedAccountColumns: string[] = [
    "name",
    "subtype",
    "number",
    "balance",
    "action",
  ];
  public displayedContactColumns: string[] = [
    "updatedAt",
    "contactTypeName",
    "description",
    "assignedUserName",
    "action",
  ];
  public displayedMeetingColumns: string[] = [
    "date",
    "attended",
    "note",
    "action",
  ];
  public displayedRequestColumns: string[] = [
    "createdAt",
    "unitName",
    "description",
    "statusName",
  ];
  public displayedViolationColumns: string[] = [
    "date",
    "unitName",
    "description",
    "statusName",
  ];

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;

  ngOnChanges() {
    this.accounts = [];

    if (this.associationInfo.options) {
      for (const option of this.associationInfo.options) {
        if (option.key == "details") {
          for (const detail of option.values) {
            this.details[detail.key] = detail.value;
          }
        } else if (option.key == "policies") {
          for (const detail of option.values) {
            this.policies[detail.key] = detail.value;
          }
        } else if (option.key == "services") {
          for (const detail of option.values) {
            this.services[detail.key] = detail.value;
          }
        }
      }
    }

    this.contacts.data = this.associationInfo.contacts;
    this.meetings.data = this.associationInfo.meetings;
    this.violations.data = this.associationInfo.violations;
    this.requests.data = this.associationInfo.requests;
    this.statistics.unitTypes = this.associationInfo.statistics;
    this.logs = this.associationInfo.logs;
    const bankAccountsData = [];
    if (this.associationInfo.accounts) {
      for (const act of this.associationInfo.accounts) {
        if (act.type == "Bank") {
          bankAccountsData.push(act);
        } else {
          this.accounts.push({
            id: act.id,
            type: act.type,
            name: act.name,
            balance: act.balance,
            change: 123,
          });
        }
      }
    }
    this.bankAccounts.data = bankAccountsData;
  }

  constructor(
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  public slideNext() {
    this.swiper?.swiperRef.slideNext(100);
  }

  public slidePrev() {
    this.swiper?.swiperRef.slidePrev(100);
  }

  public editAssociation(menu: string, data: any): void {
    this.dialog.open(GlobalFormDialog, {
      ...dialogConfig.formEdit,
      data: {
        data,
        menu,
        module: "association",
      } as GlobalFormDialogDataModel,
    });
  }

  public actionDetail(): void {
    this.editAssociation("general", this.associationInfo);
  }
}
